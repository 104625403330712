/*----------  Footer  ----------*/

footer
{
	@include position;

	.footer{
		&-wrapper{
			padding: 212px 0px 122px;
			background: linear-gradient(301.74deg, rgba(0, 0, 0, 0.2) 12.61%, rgba(0, 0, 0, 0) 59.88%), $brand-info;
			overflow-x: hidden;
		}

		&-title{
			margin-bottom: 49px;
			h6{
				a{
					color: $brand-primary;
					&:hover, &:active, &:focus{
						color: $white-color;
					}
				}
			}
		}
		
		&-container-block{
			@include display-flex(row, flex-start, space-between, wrap);
			margin-bottom: 151px;

			.contact-form{
				max-width: 520px;
				width: 100%;

				.form-group-wrapper{
					.form-group{
						margin-bottom: 38px;

						.form-input{
							background-color: transparent;
							border: none;
							padding: 0px 0px 8px;
							@include font-p($font-base, $weight-regular, 1.6, $white-color);
							border-bottom: 1px solid rgba(255, 255, 255, 0.15);
							margin-bottom: 5px;
							@include transition;
							border-radius: 0px;
							
							&::-webkit-input-placeholder{
								color: rgba(255, 255, 255, 0.25);
								@include transition;
							}
	
							&::-ms-input-placeholder{
								color: rgba(255, 255, 255, 0.25);
								@include transition;
							}
	
							&::placeholder {
								color: rgba(255, 255, 255, 0.25);
								@include transition;
							}
							
							&:hover, &:active, &:focus{
								border-color: $white-color;
							}
						}

						input:-webkit-autofill,
						input:-webkit-autofill:hover, 
						input:-webkit-autofill:focus,
						textarea:-webkit-autofill,
						textarea:-webkit-autofill:hover,
						textarea:-webkit-autofill:focus,
						select:-webkit-autofill,
						select:-webkit-autofill:hover,
						select:-webkit-autofill:focus {
							-webkit-text-fill-color: white;
							-webkit-box-shadow: 0 0 0px 1000px transparent inset;
							transition: background-color 5000s ease-in-out 0s;
						}

						label{
							@include font-p($font-sm, $weight-regular, 1.6, $white-color);
							margin-bottom: 0px;
						}
						textarea{
							resize: none;
						}
					}

					.textarea-form-group{
						margin-bottom: 30px;
					}

					.form-checkbox{
						margin:0px 0px 8px;
						
						.custom-checkbox{
							position: absolute;
							opacity: 0;
						
							& + label{
								cursor: pointer;
								padding: 0;
								@include display-flex(row, flex-start, flex-start, initial, false);
	
								p{
									@include font-p($font-sm, $weight-regular, 1.6, $brand-primary);
									margin-left: 17px;
								}
	
								span {
									max-width: 20px;
									width: 100%;
									height: 20px;
									background: transparent;
									border: 2px solid $brand-primary;
									border-radius: 3px;
									@include transition;
									@include position;
									top: 4px;
								}
							}
		
							// Box hover
							&:hover + label {
								span {
									background: $white-color;
									border-color: $white-color;
								}
							}
						
							// Box checked
							&:checked + label {
								span {
									background: $white-color;
									border-color: $white-color;
								}
							}
						
							// Disabled state label.
							&:disabled + label {
								span {
									color: $white-color;
									cursor: auto;
								}
							}
						
							// Disabled box.
							&:disabled + label {
								span {
									box-shadow: none;
									background: #ddd;
								}
							}
						
							// Checkmark. Could be replaced with an image
							&:checked + label {
								span::after {
									content: '\f00c';
									font-family: $font-fontawesome;
									font-weight: $weight-bold;
									@include position(absolute, 1, 0, auto, 0);
									font-size: $font-base;
									line-height: 1;
									color: $brand-info;
								}
							}
						}    										
					}
				}

				.form-submit{
					margin-top: 44px;
					text-align: right;

					button{
						padding: 12px 23px 12px 29px;
						border-radius: 70px;
						@include font-p($font-sm, $weight-semibold, 1.6, $brand-info);
						svg{
							@include transition;
							margin-left: 10px;
						}
						
						&:hover, &:active, &:focus{
							svg{
								transform: rotate(405deg);
							}
						}
					}
				}
			}
		}
		
		&-container-details{
			max-width: 350px;
			width: 100%;
			@include display-flex(row, flex-start, space-between, wrap);

			.contact-details-list{
				max-width: 240px;
				width: 100%;

				.contact-details{
					margin-top: 31px;

					li{
						@include display-flex(row, flex-start);
						margin-bottom: 10px;

						&:last-of-type{
							margin-bottom: 0px;
						}

						svg{
							margin: 4px 18px 0px 0px;
						}
						a{
							@include font-p($font-base, $weight-regular, 1.6, $brand-primary);
							&:hover, &:active, &:focus{
								color: $white-color;
							}
						}
					}
				}
				.licensed{
					p{
						margin-left: 33px;
						@include font-p($font-base, $weight-medium, 1.6, $brand-primary);
					}
				}
			}

			.social-media{
				a{
					margin-right: 8px;

					&:last-of-type{
						margin-right: 0px;
					}
				}
			}
		}

		&-container-cta{
			@include display-flex(row, flex-start, space-between, wrap);
			p{
				@include font-p($font-sm, $weight-regular, 1.6, $brand-primary);
				max-width: 460px;
				width: 100%;
				a{
					display: inline;
					color: $white-color;

					&:hover, &:active, &:focus{
						color: $brand-dark;
					}
				}
			}
			.develop{
				text-align: right;
			}
		}

		@include media-breakpoint-down(lg){
			&-container-details{
				justify-content: normal;
			}
		}

		@include media-breakpoint-down(md){
			&-container-details{
				@include display-flex(column, center);
				max-width: 207px;

				.contact-details-list{
					margin-bottom: 20px;

					.contact-details li a{
						font-size: $font-sm;
					}

					.licensed p{
						font-size: $font-sm;
					}
				}
			}
			&-container-block .contact-form{
				max-width: 450px;
			}

			&-container-cta{
				.terms{
					max-width: 392px;
				}
				.develop{
					max-width: 266px;
				}
			}
		}

		@include media-breakpoint-down(sm){
			&-wrapper{
				padding: 150px 0px 60px;
			}
			&-container-block{
				@include display-flex(column, center, center);
				
				.contact-form{
					max-width: none;
					margin-top: 40px;
				}
			}
			
			&-container-cta{
				@include display-flex(column, center, center);
				.terms{
					max-width: none;
					margin-bottom: 10px;
					text-align: center;
				}
				.develop{
					max-width: none;
					text-align: center;
				}
				p{
					font-size: $font-xs;
				}
			}
		}

		@include media-breakpoint-down(xs){
			&-container-block{
				margin-bottom: 90px;

				.contact-form{
					.form-group-wrapper{
						.form-group{
							.form-input{
								font-size: $font-sm;
							}
						}
					}
					.form-submit{
						text-align: center;
					}
				}
			}
		}
		// @media screen and (max-width:431px){
	}
}