/*----------  Header  ----------*/

header
{
	@include position(absolute, 5, 0, 0, 0);
	@include transition;
	transition-duration: 1s;

	.preheader{
		&-wrapper{
			padding: 20px 20px 12px 20px;
		}
		&-container{
			@include display-flex(row, center, flex-end);
		}
		
		@include media-breakpoint-down(sm){
			&-wrapper{
				display: none;
			}
		}
	}

	.language{
		&-container{
			position: relative;
			z-index: 1100;

			a{
				@include font-p($font-xs, $weight-semibold, 1, $brand-dark);
				display: block;
			}

			.current{
				position: relative;
				z-index: 2;
				color: $white-color;
				font-size: $font-xs;

				svg{
					margin-left: 5px;
					border-radius: 5px;

					.white-bg{
						transform: translateX(6px);
					}
				}
			}

			&:hover, &:active, &:focus
			{	
				.language-list {
					@include transition;
					@include opacity(1);
					@include pointer-events(all);
				}
			}
		}

		&-list{
			@include position(absolute, 3, calc(100% - 7px), -20px, -6px);
			@include opacity(0);
			@include pointer-events(none);
			@include border-radius(2px);
			@include transition;
			padding: 12px 0px 0px;
			li{
				a {
					@include display-flex(row, center, flex-end);
					padding: 8px 4px;
					color: $white-color;
					opacity: 0.5;

					svg{
						margin-left: 5px;
						border-radius: 5px;

						.white-bg{
							transform: translateX(6px);
						}
					}
					&:hover, &:active, &:focus
					{
						opacity: 1;
					}
				}		
			}
			
			@include media-breakpoint-down(sm){
				left: 0px;
				right: -15px;
				li a{
					justify-content: flex-start;
					padding: 8px 0px;
				}
			}
		}
	}

	.mobile-language-container{
		display: none;
		margin-left: 20px;

		@include media-breakpoint-down(sm){
			display: block;
		}
		@media screen and (max-width:350px){
			margin-left: 10px;
		}
	}

	.header{
		&-container{
			@include display-flex(row, center, space-between, nowrap);

			.brand-wrapper{
				@include display-flex(row, center, normal);
			}

			.nav{
				&-wrapper{
					@include display-flex(row, center, normal, nowrap);
				}
				&-container{
					.nav-list{
						@include display-flex(row, flex-start, normal, nowrap);
						&:hover, &:active, &:focus{
							li .nav-link{
								color: $brand-primary;
							}
						}
						li{
							.nav-link{
								@include font-p($font-base, $weight-semibold, 1, $white-color);
								padding: 0.5rem 20px;	
								
								&:hover, &:active, &:focus{
									color: $white-color;
								}
							}
						}
					}
				}
				&-toggler-wrapper{
					display: none;
				}
			}	

			@include media-breakpoint-down(md){
				.nav{
					&-container{
						.nav-list{
							li{
								.nav-link{
									padding: 0.5rem 10px;
									font-size: $font-sm;
								}
							}
						}
					}
				}
			}

			@include media-breakpoint-down(sm){
				@include display-flex(row,center,space-between);

				.brand-wrapper {
					// margin: 0px 20px 0px 0px;
					@include position(relative, 1000);
				}
			
				.nav{
					&-wrapper {
						.nav-toggler {
							&-wrapper {
								display: block;	
								margin-left: 15px;
								@include position(relative, 1000);
							}
							color: $white-color;
							font-size: 28px;
							@include transition;
							&.open {
								@include transform(rotate(180deg));
								.las:before {
									content: '\f00d';
									font-size: 16px;
									padding: 6px;
									border: 1px solid rgba(255, 255, 255, 0.5);
									border-radius: 99px;
								}
							}
						}
					}

					&-container{
						background-color: rgba(33, 37, 87, 0.9);
						margin: 0;
						// padding-left: 30px; 
						@include position(fixed, 999, 0, 0, 0, 0);
						@include display-flex(column, center, center);
						@include opacity(0);
						@include pointer-events(none);
						@include transition;
						height: 100vh;

						&.show {
							@include opacity(1);
							@include pointer-events(all);
						}
					
						.nav-list {
							@include display-flex(column, center, center);

							.nav-item{
								margin-bottom: 10px;
								
								.nav-link{
									font-size: $font-lg;
									letter-spacing: 0.02em;
								}
							}

							li:last-child{
								margin: 0;
							}
						}
					}
				}
			}
			
			@media screen and (max-width:350px){
				.brand-wrapper{
					.brand-logo{
						max-width: 120px;
						width: 100%;
					}
				}
				.nav-wrapper{
					.nav-toggler{
						font-size: 24px;

						&-wrapper{
							margin-left: 8px;
						}

						&.open {
							.las:before {
								font-size: 16px;
								padding: 7px;
							}
						}
					}
				}
			}
		}
		@include media-breakpoint-down(sm){
			&-wrapper{
				padding: 20px 0px 0px;
			}
		}
	}
}

.navbar-fixed{
	position: fixed;
	top: 0px;
	background: linear-gradient(180deg, rgba(33, 37, 87, 0.7) 0%, rgba(33, 37, 87, 0) 100%);
	@include transition;
	transition-duration: 1s;

	.header-wrapper{
		padding-bottom: 49px;
	}
}