/*----------  Index View  ----------*/
.home-main{
    .banner{
        &-wrapper{
            // @include display-flex(row, flex-end, flex-start);
            // background-image: url('/assets/front/app//assets/images/brand-hero-new.png');
            height: 100vh;
            @include background-defaults;
            background-position: center right;
            .hero-container{
                margin: 0px auto;
                height: 100%;
            }
        }
        &-container{
            // margin-bottom: 60px;
            @include position;
            height: 100%;

            &-title{
                max-width: 795px;
                width: 100%;
                // margin-bottom: 192px;
                @include position(absolute, 2, 50%, auto, auto, auto);
                transform: translateY(-50%);
                p{
                    max-width: 592px;
                    width: 100%;
                    margin-top: 20px;
                }
            }
            
            &-block{
                @include display-flex(row, center, space-between);
                width: 100%;
                @include position(absolute, 2, auto, auto, auto, 60px);
                
                .discover-btn{
                    @include font-p($font-base, $weight-semibold, 1.5, $white-color);
                    padding: 24px 32px 24px 30px;
                    border: 2px solid $white-color;
                    border-radius: 80px;
                    
                    svg{
                        @include transition;
                        margin-left: 19px;
                        path{
                            @include transition;
                        }
                    }

                    &:hover, &:active, &:focus{
                        background-color: $white-color;
                        color: $brand-dark;

                        svg{
                            transform: rotate(360deg);
                            path{
                                stroke: $brand-dark;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(md){
                &-block{
                    p{
                        font-size: $font-base;
                        max-width: 400px;
                    }
                    .discover-btn{
                        font-size: $font-sm;
                        padding: 20px 26px;
                    }
                }
            }

            @include media-breakpoint-down(sm){
                &-block{
                    @include display-flex(column, center, center);

                    p{
                        max-width: none;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }
            }

            @include media-breakpoint-down(xs){
                &-title{
                    top: 58%;
                }
                &-block{
                    .discover-btn{
                        padding: 14px 18px;
                    }
                }
            }

            @media screen and (max-width:360px){
                &-title{
                    // top: 40%;
                }
                &-block{
                    bottom: 30px;
                    p{
                        font-size: $font-sm;
                        max-width: 400px;
                    }
                    .discover-btn{
                        font-size: $font-xs;
                        padding: 14px 20px;

                        svg{
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }

    .about{
        &-wrapper{
           padding: 159px 0px 163px;
           @include position;
           &::before{
               content: '';
               height: 100%;
               max-width: 575px;
               width: 100%;
               background: linear-gradient(90deg, #E8ECF2 0%, rgba(232, 236, 240, 0) 100%);
               @include position(absolute, -1, 0,0,0,0);
           }
        }
        &-container{
            h1{
                color: $brand-dark;
                margin-bottom: 87px;
            }
            
            &-content{
                @include display-flex(row, flex-start, space-between, wrap);
            }
            &-count{
                max-width: 250px;
                width: 100%;
                margin-top: 18px;

                .count-block{
                    margin-top: 50px;
                    &:first-of-type{
                        margin-top: 0px;
                    }
                    .count{
                        @include font-p(62px, $weight-semibold, 0.85, #4690D7);
                        background: -webkit-linear-gradient(#4690D7, #0C3F71);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        letter-spacing: -0.05em;
                    }
                    p{
                        @include font-p($font-base, $weight-medium ,1.7, #4690D7);
                        background: -webkit-linear-gradient(#4690D7, #0C3F71, #212557);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            &-details{
                max-width: 645px;
                width: 100%;
                @include display-flex(row, flex-start, space-between, wrap);
                .details-block{
                    max-width: 235px;
                    width: 100%;

                    &:first-of-type{
                        margin-bottom: 68px;
                    }

                    .logo{
                        span{
                            padding: 16px;
                            background-color: rgba(246, 247, 249, 1);
                            border-radius: 3px;
                        }
                    }
                    .content{
                        margin-top: 18px;
                        .title{
                            color: $brand-dark;
                            margin-bottom: 22px;
                        }
                        p{
                            @include font-p($font-base, $weight-medium, 1.7, #646689);
                        }
                    }
                }
            }
        }

        &-footer{
            &-wrapper{
                padding: 0px 0px 179px;
                @include position;

                .about-footer-bg-wrapper{
                    @include position(absolute, -1, 0px, 0px, 0, 0);
                    max-width: 575px;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, #E8ECF2 0%, rgba(232, 236, 240, 0) 100%);
                }

                &::before{
                    content: '';
                    @include position(absolute, 1, -220px, -23px, auto, auto);
                    width: 495px;
                    height: 492px;
                    padding: 2px;
                    border-top: 2px solid rgba(40, 143, 149, 0.08);
                    border-left: 2px solid rgba(40, 143, 149, 0.08);
                    border-right:3px solid rgba(33, 37, 87, 0.04);
                    border-bottom:3px solid rgba(33, 37, 87, 0.04);
                    background-image: linear-gradient(rgba(40, 143, 149, 0.08), rgba(33, 37, 87, 0.04)),linear-gradient(rgba(40, 143, 149, 0.08), rgba(33, 37, 87, 0.04));
                    background-size:0px 100%;
                    background-repeat:no-repeat;
                    border-radius: 350px;
                    transform: matrix(0.9, 0.13, 0.56, 0.99, 0, 0);
                }
                &::after{
                    content: '';
                    @include position(absolute, 1, -278px, 30px, auto, auto);
                    width: 607px;
                    height: 607px;
                    padding: 2px;
                    border-top: 2px solid rgba(40, 143, 149, 0.08);
                    border-left: 2px solid rgba(40, 143, 149, 0.08);
                    border-right:3px solid rgba(33, 37, 87, 0.04);
                    border-bottom:3px solid rgba(33, 37, 87, 0.04);
                    background-image: linear-gradient(rgba(40, 143, 149, 0.08), rgba(33, 37, 87, 0.04)),linear-gradient(rgba(40, 143, 149, 0.08), rgba(33, 37, 87, 0.04));
                    background-size:0px 100%;
                    background-repeat:no-repeat;
                    border-radius: 350px;
                    transform: matrix(0.9, 0.13, 0.56, 0.99, 0, 0);
                }
            }
            &-container{
                max-width: 645px;
                width: 100%;
                margin-left: auto;

                p{
                    @include font-p($font-xl, $weight-medium, 1.7, $brand-dark);
                }
            }
        }

        @include media-breakpoint-down(md){
            &-container{
                &-count{
                    max-width: 160px;
                    .count-block{
                        p{
                            font-size: 14px;
                        }
                        .count{
                            font-size: 50px;
                        }
                    }
                }
                &-details{
                    max-width: 465px;
                    .details-block{
                        max-width: 220px;
                    }
                }
            }
            &-footer-wrapper{
                &::after{
                    width: 550px;
                    height: 550px;
                }
            }
        }

        @include media-breakpoint-down(sm){
            &-container{
                h1{
                    margin-bottom: 50px;
                }

                &-content{
                    @include display-flex(column, center, center);    
                }
                &-count{
                    margin: 0px 0px 40px;
                    
                    .count-block{
                        text-align: center;
                    }
                }
                &-details{
                    max-width: none;
                }
            }
            &-footer{
                &-wrapper{
                    &::before{
                        display: none;
                    }
                    &::after{
                        display: none;
                    }
                }
                &-container{
                    p{
                        font-size: 22px;
                    }
                }
            }
        }

        @include media-breakpoint-down(xs){
            &-container{
                h1{
                    text-align: center;
                }
            }
            &-footer-container{
                p{
                    text-align: center;
                }
            }
        }

        @media screen and (max-width:480px){
            &-container-details{
                @include display-flex(column,center,center);

                .details-block{
                    margin-bottom: 40px;
                    text-align: center;
                    max-width: 300px;

                    &:first-of-type{
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }


    .specialisation{
        &-wrapper{
            padding: 186px 0px 25px;
            background: linear-gradient(301.74deg, rgba(0, 0, 0, 0.2) 12.61%, rgba(0, 0, 0, 0) 59.88%), #155DA4;
            overflow-x: hidden;
        }
        &-container{
            .title-block{
                max-width: 880px;
                width: 100%;
                margin-bottom: 47px;
                p{
                    margin-top: 14px;
                    @include font-p($font-xl, $weight-medium, 1.5, $white-color);
                }
            }
        }

        &-tab-container-inner{
            .mobile-specialisation-block{
                display: none;
            }
        }

        &-tabs-link{
            max-width: 430px;
            width: 100%;
            margin-right: 32px !important;

            .specialisation-tab{
                padding: 40px;
                @include font-p($font-base, $weight-semibold, 1.7, $white-color);
                color: rgba(255, 255, 255, 0.3);
                background: rgba(255, 255, 255, 0.07);
                border-radius: 3px;
                margin-bottom: 8px;
                @include display-flex(row, center, space-between);
                
                &:last-of-type{
                    margin-bottom: 0px;
                }

                &:hover{
                    color: $brand-info;
                    background-color: $white-color;
                    opacity: 0.7;
                    svg path{
                        stroke: $brand-info;
                    }
                }
            }

            .mobile-specialisation-tab{
                width: 100%;
                color: $brand-info;
                background-color: $white-color;

                &:hover{
                    opacity: 1;
                }
                svg path{
                    stroke: $brand-info;
                }
            }
            
            .collapsed{
                color: rgba(255, 255, 255, 0.3);
                background: rgba(255, 255, 255, 0.07);

                &:hover{
                    opacity: 0.7;
                }
                svg path{
                    stroke: #6695c3;
                }
            }

            .active{
                color: $brand-info;
                background-color: $white-color;
                
                &:hover{
                    opacity: 1;
                }
                svg path{
                    stroke: $brand-info;
                }
            }
        }

        &-tabs-content{
            max-width: 598px;
            width: 100%;

            .mobile-card-body{
                padding: 10px 0px;
            }
        }
        
        &-content-text{
            margin-top: 27px;
            .title{
                color: $white-color;
            }
            p{
                @include font-p($font-base, $weight-regular, 1.7, $brand-primary);
            }

            ul{
                margin: 8px 0px 8px;
            }

            li{
                @include font-p($font-base, $weight-regular, 1.7, $brand-primary);
            }
        }

        &-team-btn{
            margin-top: 175px;
            @include display-flex(row, center, center);
            a{
                @include font-p($font-base, $weight-semibold, 1.7, $white-color);
                svg{
                    @include transition;
                    margin-left: 13px;
                    path{
                        @include transition;
                    }
                }

                &:hover, &:active, &:focus{
                    color: $brand-primary;
                    svg{
                        transform: rotate(360deg);
                        path{
                            stroke: $brand-primary;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(lg){
            &-block{
                align-items: flex-start !important;
            }

            &-tabs-link{
                max-width: 300px;
                margin-right: 20px !important;
                
                .specialisation-tab{
                    padding: 27px;
                }
            }
        }

        @include media-breakpoint-down(md){
            &-tabs-link{
                max-width: 250px;

                .specialisation-tab{
                    font-size: $font-sm;
                    padding: 27px 20px;
                }
            }

            &-content-text{
                p{
                    max-width: none;
                    &:first-of-type{
                        margin-bottom: 20px;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm){
            &-wrapper{
                padding: 50px 25px;
            }

            &-container{
                .title-block{
                    p{
                        font-size: 22px;
                    }
                }
            }

            &-tab-container-inner{
                .desktop-specialisation-block{
                    display: none !important;
                }

                .mobile-specialisation-block{
                    display: block;
                }
            }
            
            &-block{
                margin-bottom: 8px;
            }

            &-tabs-link{
                max-width: none;
                width: 100%;

                .specialisation-tab{
                    font-size: $font-md;
                }
                .mobile-specialisation-tab{
                    svg{
                        transform: rotateZ(-90deg);
                        @include transition;
                    }
                }

                .collapsed{
                    svg{
                        transform: rotateZ(90deg);
                        @include transition;
                    }
                }
            }

            &-team-btn{
                margin-top: 60px;
            }
        }

        @include media-breakpoint-down(xs){
            &-tabs-link{
                .specialisation-tab{
                    font-size: 13px;
                }
            }
        }

        @media screen and (max-width:421px){
            &-tabs-link{
                @include display-flex(column, center, center);
                flex-direction: column !important;
                .specialisation-tab{
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }
        
        @media screen and (max-width:330px){
            &-tabs-link{
                .specialisation-tab{
                    padding: 24px 12px;
                    font-size: 12px;
                }
            }
        }
        @media screen and (max-width:295px){
            &-tabs-link{
                .specialisation-tab{
                    justify-content: center;
                    svg{
                        display: none;
                    }
                }
            }
        }
    }

    .team{
        &-wrapper{
            padding: 180px 0px 35px;
            @include background-defaults;
            overflow-x: hidden;
        }

        &-members-listing{
            &-inner{
                margin: 0px -10px;
                @include display-flex(row, flex-start, center, wrap);

                .member-block{
                    margin: 0px 10px 51px 10px;
                    max-width: 340px;
                    width: 100%;
                    box-shadow: 0px 24px 34px rgba(33, 37, 87, 0.2);
                    background-color: $white-color;

                    .member-image-wrapper {
                        height: 369px;
                        @include background-defaults;
                    }
                    .member-details{
                        padding: 21px 29px 25px 29px;
                        .member-info{
                            @include display-flex(row, center, space-between, wrap);

                            &-item{
                                width: 60%;
                                @include display-flex(row, flex-start, normal);
                                @include font-p($font-sm, $weight-medium, 1.5, $brand-secondary);

                                &:first-of-type{
                                    margin-bottom: 5px;
                                }

                                &:nth-child(even){
                                    justify-content: flex-end;
                                    width: 40%;
                                }
                            }

                            .name{
                                @include font-p($font-base, $weight-semibold, 1.5, $brand-dark);
                            }
                            
                            .language{
                                span{
                                    background: rgba(144, 146, 171, 0.25);
                                    border-radius: 3px;
                                    margin-right: 4px;
                                    padding: 2px 7px;

                                    &:last-of-type{
                                        margin-right: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @include media-breakpoint-down(lg){
            &-members-listing-inner .member-block{
                margin: 0px 7px 51px 7px;
                max-width: 299px;

                .member-details .member-info{
                    .name{
                        font-size: $font-sm;
                    }
                    &-item{
                        font-size: $font-xs;
                    }
                }
            }
        }

        @include media-breakpoint-down(md){
            &-members-listing-inner{
                justify-content: center;

                .member-block{
                    max-width: 320px;
                }
            }
        }

        @include media-breakpoint-down(sm){
            &-wrapper{
                padding: 90px 0px 30px;
            }
            &-members-listing-inner{
                .member-block{
                    margin: 0px 7px 30px 7px;
                    max-width: 242px;

                    .member-image-wrapper{
                        height: 266px;
                    }
                    .member-details{
                        padding: 12px 29px;
                        .member-info{
                            @include display-flex(column, flex-start, normal);

                            .name , .language{
                                width: 100%;
                                font-size: $font-sm;
                            }
                        }
                        .member-info-item{
                            width: 100%;
                            margin-bottom: 5px;
                            font-size: $font-sm;
                            &:nth-child(even){
                                justify-content: flex-start;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .client{
        &-wrapper{
            padding: 131px 0px 46px;
            overflow: hidden;
        }
        &-container{
            @include display-flex(row, flex-start, space-between);
        }
        &-title{
            max-width: 155px;
            width: 100%;
            h6{
                @include font-p($font-base, $weight-semibold, 1.7, $brand-secondary);
            }
        }
        &-logos{
            max-width: 830px;
            width: 100%;

            .logo-listing{
                @include display-flex(row, center, normal, wrap);

                .logo-block{
                    width: 20%;
                    @include display-flex(row, center, center);
                    margin-bottom: 54px;
                }
            }
        }

        &-company-wrapper{
            padding: 119px 0px 200px;
            @include position;

            &::before{
                content: '';
                @include position(absolute, -1, 0px, 0px, auto);
                height: 622px;
                width: 382px;
                background: linear-gradient(90deg, #F6F7F9 0%, rgba(232, 236, 240, 0) 100%);
            }
        }
        &-company-container{
            .client-logos{
                .logo-listing{
                    .logo-block{
                        &:last-of-type{
                            width: auto;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md){
            &-logos{
                .logo-listing .logo-block{
                    width: 33.33%;
                }
            }
        }
        @include media-breakpoint-down(sm){
            &-wrapper{
                padding: 70px 0px 0px;
            }
            
            &-company-wrapper{
                padding: 100px 0px;
            }

            &-container{
                @include display-flex(column, flex-start, normal);
            }
            &-title{
                max-width: none;
                margin-bottom: 20px;
            }
            &-company-wrapper{
                &::before{
                    display: none;
                }
            }
        }

        @media screen and (max-width:528px){
            &-company-container .client-logos .logo-listing .logo-block:last-of-type{
                width: 50%;
            }
            &-logos{
                .logo-listing .logo-block{
                    width: 33.33%;
                }
            }
        }

        @media screen and (max-width:400px){
            &-logos{
                .logo-listing .logo-block{
                    width: 50%;
                }
            }
            &-company-container .client-logos .logo-listing .logo-block:last-of-type{
                width: auto;
            }
        }
    }
    
    .career{
        &-container{
            .title{
                text-align: center;
                // margin-bottom: 24px;
                h6{
                    @include font-p($font-base, $weight-semibold, 1.7, $brand-secondary);
                    a{
                        color: $brand-info;
                        &:hover, &:active, &:focus{
                            color: $brand-dark;
                        }
                    }
                }
            }

            &-block{
                background-color: #E8ECF0;
                @include position(relative, 3);
                transform: translateY(40px);
                &-inner{
                    padding: 95px 132px 98px;
                }
            }
        }
        
        &-text{
            margin-bottom: 43px;
            p{
                @include font-p($font-lg, $weight-medium, 1.5, $brand-dark);
            }
        }
        
        &-details{
            @include display-flex(row, flex-start, space-between, wrap);

            &-block{
                width: 51%;
                @include display-flex(column, flex-start);
                margin-bottom: 22px;

                .title{
                    @include font-p($font-base, $weight-semibold, 1.5, $brand-dark);
                    margin-bottom: 0px;
                }
                .text{
                    @include font-p($font-base, $weight-medium, 1.5, $brand-secondary);
                }

                &:nth-child(even){
                    width: 44%;
                }
            }
        }

        &-contact{
            margin-top: 44px;
            text-align: right;

            p{
                @include font-p($font-base, $weight-medium, 1.3, $brand-dark);
                a{
                    @include font-p($font-lg, $weight-medium, 1.5, $brand-info);
                    display: block;

                    &:hover, &:active, &:focus{
                        color: $brand-dark;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg){
            &-container-block-inner{
                padding: 70px 60px;
            }
        }

        @include media-breakpoint-down(sm){
            &-container-block-inner{
                padding: 55px 45px;
            }
            &-text{
                p{
                    font-size: $font-md;
                }
            }
            &-details-block{
                .text , .title{
                    font-size: $font-sm;
                }
            }
            &-contact{
                p{
                    font-size: $font-sm;

                    span{
                        font-size: $font-md;
                    }
                }
            }
        }
        
        @media screen and (max-width:431px){
            &-container-block-inner{
                padding: 40px 30px;
            }
            &-details{
                @include display-flex(column, center, center);

                &-block{
                    width: 100%;

                    &:nth-child(even){
                        width: 100%;
                    }
                }
            }

        }
    }
}