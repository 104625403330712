.thankyou-main{
    .thank{
        &-banner{
            &-wrapper{
                height: 100vh;
                @include background-defaults;
            }
            
            &-container{
                @include display-flex(column, flex-start,center);
                height: 100%;
                @include position;
                .content{
                    h2{
                        @include font-p($font-xxl, $weight-semibold, 1, $white-color);
                    }
                    p{
                        @include font-p($font-md, $weight-medium, 1.5, $white-color);
                        margin-top: 11px;
                    }
                }
                
                .thankyou-btn{
                    @include position(absolute, 2, auto, 0px, auto, 60px);

                    .return{
                        padding: 24px 32px;
                        @include font-p($font-base, $weight-semibold, 1.5, $white-color);
                        border: 2px solid $white-color;
                        border-radius: 80px;

                        svg{
                            margin-left: 19px;
                            @include transition;
                        }

                        &:hover, &:active, &:focus{
                            background-color: $white-color;
                            color: $brand-dark;

                            svg{    
                                transform: rotate(180deg);
                                path{
                                    stroke: $brand-dark;
                                }
                            }
                        }
                    }
                }

                @include media-breakpoint-down(sm){
                    .content{
                        h2{
                            font-size: 50px;
                        }
                        p{
                            font-size: $font-base;
                        }
                    }

                    .thankyou-btn{
                        .return{
                            padding: 16px 20px;
                        }
                    }
                }
            }
        }
    }
}