/*----------  Body  ----------*/

body
{
	@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
	-webkit-font-smoothing: antialiased;
	
	overflow-y: scroll;
    
    // Chrome
    &::-webkit-scrollbar{
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

li{
	list-style-type: none;
}

h1{
	@include font-p($font-xxl,$weight-semibold, 1, $white-color);
	
	@include media-breakpoint-down(lg){
		font-size: 50px;		
	}

	@include media-breakpoint-down(sm){
		font-size: 36px;
	}

	@media screen and (max-width:380px){
		font-size: 28px;
	}
}

h6{
	@include font-p($font-base,$weight-semibold, 1.7, $brand-primary);

	@include media-breakpoint-down(sm){
		font-size: $font-sm;
	}
}

p{
	@include font-p($font-md,$weight-medium, 1.5, $white-color);
	@include media-breakpoint-down(sm){
		font-size: $font-base;
	}
	@include media-breakpoint-down(xs){
		font-size: $font-sm;
	}
}

button{
	outline: none;
	border: none;
}

.grecaptcha-badge{
	opacity: 0;
	cursor: none;
}

.p-x-n10{
	padding: 0px 10px;
}

.m-x-n10{
	margin: 0px -10px;
}

.alert{
	@include position(fixed, 9999, 50%, 50%);
	transform: translate(-50%, -50%);
}

.alert-success{
	color: $brand-dark;
	background-color: $white-color;
	padding: 50px;
	font-size: $font-base;
}

.cookiebar{
	&-wrapper{
		@include position(fixed, 999, auto, 0px, 0px, 0px);
		background-color: $brand-info;
		box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.2);
	}

	&-container{
		padding: 15px 0px;
		@include display-flex(row,center,space-between,initial);

		.cookie-content{
			p{
				@include font-p($font-base, $weight-regular, 1.3, $white-color);

				.cookie-content-link{
					color: $white-color;
					@include transition;

					&:hover{
						color: $brand-dark;
					}
				}
			}
			
			&-bold{
				font-weight: $weight-bold;
			}
		}

		.btnAcceptCookie{
			padding: 6px 14px 7px;
			background-color: $white-color;
			@include font-p($font-base, $weight-medium, 1.2, $brand-dark);
			border-radius: 30px;
			border: 1px solid $white-color;
			@include transition;

			&:hover{
				background-color: $brand-dark;
				border-color: $brand-dark;
				color: $white-color;
			}
		}
	}

	@include media-breakpoint-down(md){
		&-container{
			flex-direction: column;
			justify-content: center;
		
			.cookie-content{
				margin-bottom: 10px;
				text-align: center;
			}
		}
	}
}
